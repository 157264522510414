@media screen and (min-width: 100px) and (max-width: 700px){

  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }

  .title {
    background-image:url("https://i.ibb.co/64kfxJm/generative-2-removebg-preview.png");
    height: 20vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
      
  }
  
  .wall {
    display: flex;
    justify-content: space-between;
  }
  
 
  
  .con {
    text-align: left;
    color: rgb(0, 0, 0);    
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    font-family:fantasy;
    font-size: 15px; 
    display: inline-block;
  
  }
  
  .num {
    text-align: right;
    color: rgb(0, 0, 0); 
    width: 100%;
    height: 100%;  
    margin: auto;
    font-size: 15px;
    font-family:fantasy;
    display: inline-block;
  }
  
 
  .vv1 {
    display: flex;
    justify-content: space-between;
    
  }

  .nn1 {
    height: 10vh;
    width: 45%;
    display: inline-block;
  }

  .bb1 {
    height: 15vh;
    width: 25%;   
    display: inline-block; 
           
  }
  
 
  
}

@media screen and (min-width: 701px) and (max-width: 1000px){

  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }

  .title {
  background-image:url("https://i.ibb.co/64kfxJm/generative-2-removebg-preview.png");
  height: 20vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
    
}

.wall {
  display: flex;
  justify-content: space-between;
}

.vv1 {
  display: flex;
  justify-content: space-between;
}

.con {
  text-align: left;
  color: rgb(0, 0, 0);    
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  font-family:fantasy;
  display: inline-block;

}

.num {
  text-align: right;
  color: rgb(0, 0, 0); 
  width: 100%;
  height: 100%;  
  margin: auto;
  font-family:fantasy;
  display: inline-block;
}

.bb1 {
  height: 15vh;
  width: 20%;   
  display: inline-block; 
  
     
}

.nn1 {
  height: 15vh;
  width: 35%;
  display: inline-block;
}

}

@media screen and (min-width: 1001px) and (max-width: 2000px){

  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }

  .title {
  background-image:url("https://i.ibb.co/64kfxJm/generative-2-removebg-preview.png");
  height: 20vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
    
}

.wall {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
}

.vv1 {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  top:10px;
}

.con {
 
    text-align: left;
    color: rgb(0, 0, 0);    
    max-width: 250%;    
    margin: auto;    
    font-family:fantasy;
    font-size: 35px; 
    display: inline-block;
  
  }

.num {
  text-align: right;
  color: rgb(0, 0, 0); 
  width: 100%;
  height: 100%; 
  margin: auto;
  font-family:fantasy;
  display: inline-block;
}

.bb1 {
  height: 10vh;
  width: 20%;   
  display: inline-block; 
  
     
}

.nn1 {
  height: 10vh;
  width: 20%;
  display: inline-block;
}

}


