

:root{
  --background-color:black;
  --text-color:white;
}


@media (prefers-color-scheme: dark) {
  :root{
    --background-color:rgb(247, 245, 245);
    --text-color:rgb(0, 0, 0);
  }
  }


@media screen and (min-width: 100px) and (max-width: 700px){
  .mainhead_1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 25px;
    font-family:fantasy;
    color:black;
    
}

.header_1{
  
  color: rgb(0, 0, 0);
  text-align: justify;
  margin-left: 2%;
  margin-right: 2%;
  font-size:15px;
}

.image_1{   
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 20vh;
  
}


.name1{
  text-align: center;
  font-family:fantasy;
}
}


@media screen and (min-width: 701px) and (max-width: 1000px){

.mainhead_1 {
  margin-left: auto;
  margin-right: auto;
  font-size:50px;
  text-align: center;
  font-style: oblique;
  color:rgb(0, 0, 0);
  
}
.header_1{
  color: rgb(0, 0, 0);
  text-align: justify;
  margin-left: 2%;
  margin-right: 2%;
  font-size:30px;
}

.image_1{   
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.name1{
  font-size: 30px;
  text-align: center;
  font-family:fantasy;
}

}



@media screen and (min-width: 1001px) and (max-width: 2000px){

.body{
  background-color:var(--background-color);
  color:var(--text-color);
}

.mainhead_1 {
  margin-left: auto;
  margin-right: auto;
  font-size:50px;
  text-align: center;
  /* font-family:fantasy; */
  color:rgb(0, 0, 0);

}

.header_1{
  color: rgb(13, 13, 13);
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  font-size:20px;
  width: 80%;
  /* font-family:fantasy; */
}

.image_1{   
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:80%;
  height:400px;
}

.name1{
  font-size: 30px;
  text-align: center;
  /* font-family:fantasy; */
}

}