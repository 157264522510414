@media screen and (min-width: 100px) and (max-width: 700px){
  
    .mainhead_3 {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 25px;
      font-family:fantasy;
      color:black;
      
  }
  
  .header_3{
    
    color: rgb(0, 0, 0);
    text-align: justify;
    margin-left: 2%;
    margin-right: 2%;
    font-size:15px;
  }
  
  .image_3{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 20vh;
  }
  
  .name_pro{
    font-family:fantasy;
  }

  }
  
  
  @media screen and (min-width: 701px) and (max-width: 1000px){

  
    .mainhead_3 {
      margin-left: auto;
      margin-right: auto;
      font-size:50px;
      text-align: center;
      font-style: oblique;
      color:rgb(0, 0, 0);
      
  }
  .header_3{
    color: rgb(0, 0, 0);
    text-align: justify;
    margin-left: 1%;
    margin-right: 1%;
    font-size:30px;
  }
  
  .image_3{   
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  }
  
  
  
  @media screen and (min-width: 1001px) and (max-width: 2000px){
  
    .mainhead_3{
      margin-left: auto;
      margin-right: auto;
      font-size:50px;
      text-align: center;
      color:rgb(0, 0, 0);
      
  }
  
  .header_3{
    
    color: rgb(0, 0, 0);
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    font-size:20px;
    width: 80%;
  }
  
  .image_3{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    height:400px;
  }

  .name_pro{
    text-align: center;
  }
  
  }