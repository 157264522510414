@media screen and (min-width: 100px) and (max-width: 700px){
.box{
    width: 100%;
    height: 150px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 80px;
    margin: 0;
    background-color: rgb(13, 13, 13);
}

.header_4{
    color: white;
    text-align: center;
    font-size: 20px;
}
.ptag{
    text-align: center;
    font-size: 15px;
    color: white;
}
.htag{
    text-align: center;
    font-size: 10px;
    color: white;
}

.footer-bottom{
    width: 100%;
    padding: 20px;
    background-color: rgb(0, 0, 0);
}
.cright{
    color: #ffffff;
    text-align: center;
    height: 10px;
}

.spantag{
    margin-left: 5px;
}
.h_5{
    color: #ffffff;
}
}

@media screen and (min-width: 701px) and (max-width: 1000px){
    .box{
        width: 100%;
        height: 180px;
        padding: 10px;
        border: 1px solid rgb(0, 0, 0);
        background-color: rgb(13, 13, 13);
    }
    
    .header_4{
        color: white;
        text-align: center;
        font-size: 40px;
    }
    .ptag{
        text-align: center;
        color: white;
        font-size: 20px;
    }
    .htag{
        text-align: center;
        color: white;
    }
    
    .footer-bottom{
        width: 100%;
        padding: 20px;
        background-color: rgb(0, 0, 0);
    }
    .cright{
        color: #ffffff;
        text-align: center;
        height: 10px;
        font-size: 20px;
    }
    
    .spantag{
        margin-left: 5px;
    }
    .h_5{
        color: #ffffff;
    }
}
    
        


@media screen and (min-width: 1001px) and (max-width: 2000px){
.box{
    width: 100%;
    height: 140px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 80px;
    margin: 0;
    background-color: rgb(13, 13, 13);
}

.header_4{
    color: white;
    text-align: center;
}
.ptag{
    color: white;
    text-align: center;
}
.htag{
    color: white;
    text-align: center;
}

.footer-bottom{
    width: 100%;
    padding: 20px;
    background-color: rgb(0, 0, 0);
}
.cright{
    color: #ffffff;
    height: 10px;
    text-align: center;
}

.spantag{
    margin-left: 5px;
   
}
.h_5{
    color: #ffffff;
}
}

