@media screen and (min-width: 100px) and (max-width: 700px){
  
    .mainhead_2 {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 25px;
      font-family:fantasy;
      color:black;
  }
  
  .h4tag_2{
    color: rgb(13, 13, 13);
    text-align: justify;
    margin-left: 2%;
    margin-right: 2%;
    font-size:15px;
  }
  
  .image_2{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 20vh;
  }
  .name_2{
    text-align: center;
    font-family:fantasy;
  }
  .name_r_1{
    font-family:fantasy;
  }
  }
  
  
@media screen and (min-width: 701px) and (max-width: 1000px){
  
    .mainhead_2 {
      margin-left: auto;
      margin-right: auto;
      font-size:50px;
      text-align: center;
      font-style: oblique;
      color:rgb(0, 0, 0);
      
  }
  .h4tag_2{
    color: rgb(0, 0, 0);
    text-align: justify;
    margin-left: 2%;
    margin-right: 2%;
    font-size:30px;
  }
  
  .image_2{   
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .name_2{
    font-size: 30px;
    text-align: center;
    font-style: oblique;
  }
  
}
  
  
  
@media screen and (min-width: 1001px) and (max-width: 2000px){
  
.mainhead_2{
  margin-left: auto;
  margin-right: auto;
  font-size:50px;
  text-align: center;
  color:rgb(0, 0, 0);
  
}
  
.h4tag_2{
  color: rgb(0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  font-size:20px;
  width: 80%;
}
  
  .image_2{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    height:400px;
  }
  
  
  .name_2{
    text-align: center;
  }

  
  .name_r_1{
    margin-left: 150px;
    text-align: left;
    font-size:40px;
  }
  
}