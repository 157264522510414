@media screen and (min-width: 100px) and (max-width: 700px){

    * {
        box-sizing: border-box;
        margin:0;
        padding: 0;
      }

    .topnav {
        display:flex;
        overflow: hidden;
        height: auto;
        width:auto;
        justify-content: space-between;
        align-items: center;
        outline:invert;
        outline-color: rgb(41, 6, 90);
        background-color: rgb(4, 30, 94);
    }
    
    
    .topnav ul {
        display: flex;
        text-align: center;
        padding: 10px 12px;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        
    }
    
    .topnav ul li {
        list-style: none;
        
    }
    
    .topnav ul li  a{
        display: flex;
        text-decoration: none;
        font: bold;
        font-size: 10px;
        font-family:serif;
        color: rgb(255, 255, 255);
        padding: 0.5rem;
        margin: 0 0.5rem;
    }
    
    
    .topnav a {
        color: #ffffff;
        border-bottom: 3px solid transparent;
      }
      
      .nav a:focus {
        border-bottom: 3px solid rgb(255, 0, 0);
        }
}

@media screen and (min-width: 701px) and (max-width: 1000px){

    .topnav {
    display:flex;
    overflow: hidden;
    height: auto;
    width:auto;
    justify-content: space-between;
    align-items: center;
    outline:invert;
    outline-color: rgb(41, 6, 90);
    background-color: rgb(4, 30, 94);
    }
    
    
    .topnav ul {
        display: flex;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        
    }
    
    .topnav ul li {
        list-style: none;
        
    }
    
    .topnav ul li  a{
        display: flex;
        text-decoration: none;
        font: bold;
        font-family:serif;
        color: rgb(255, 255, 255);
        font-size: 25px;
        padding: 0.5rem;
        margin: 0 0.5rem;
    }
    
      
    .nav a:focus {
        border-bottom: 3px solid rgb(255, 0, 0);
        }
    }
      
    


@media screen and (min-width: 1001px) and (max-width: 2000px){

.topnav {
    display:flex;
    overflow: hidden;
    height: auto;
    width:auto;
    justify-content: space-between;
    align-items: center;
    outline:invert;
    outline-color: rgb(41, 6, 90);
    background-color: rgb(4, 30, 94);
}


.topnav ul {
    display: flex;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    
}

.topnav ul li {
    list-style: none;
    
}

.topnav ul li a{
    display: flex;
    text-decoration: none;
    font: bold;
    font-family:serif;
    font-size: 20px;
    color: rgb(255, 255, 255);
    padding: 0.5rem;
    margin: 0 0.5rem;
}


.topnav a{
    color: #ffffff;
    border-bottom: 3px solid transparent;
}


.nav a:focus,nav a.active {
    border-bottom: 3px solid rgb(255, 0, 0);
    }
}






