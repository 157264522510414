@media screen and (min-width: 100px) and (max-width: 700px){

  .mainhead_patent {
    margin-left: auto;
    margin-right: auto;
    font-size:30px;
    text-align: center;
    font-family:fantasy;
    color:rgb(0, 0, 0);
        
    }
  
  .header_patent_2 {
    margin-left: auto;
    margin-right: auto;
    font-size:30px;
    text-align:center;
    font-family:fantasy;
    color:rgb(0, 0, 0);
      
  }

  .h3_patent{
    font-family:fantasy;
    font-size: 20px;
  }
  
  
  .box_patent{
    position:relative;
    left:20px;
    width: 90%;
    height: 100%;   
    padding: 10px;
    border: 5px solid rgb(0, 0, 0);
      
  }
  
  .head_patent{
    font-size: 15px;
    text-align: justify;
  }
  
  .header_patent_1{
    color: rgb(0, 0, 0);
    text-align: justify;
    margin-left: 1%;
    margin-right: 1%;
    font-size:15px;
  }

 
  
  .header_patent_2{
    color: rgb(0, 0, 0);
    text-align: justify;
    margin-left: 1%;
    margin-right: 1%;
    font-size:20px;
  }
  
    .name_patent{
      color:black;
      text-align: center;
      font-family:fantasy;
    }

    
  .head3_patent{
    color:black;
    text-align: center;
    font-family:fantasy;
    font-size: 15px;
  }
  
    .img5_1{   
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 240px;
      width: 290px;
    }
  
    .img5_2{   
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 280px;
      height: 220px;
    }
    
    .img5_3{   
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 280px;
      height: 280px;
    }
    .img5_4{   
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 280px;
      height: 220px;
    }
    .img5_5{   
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 280px;
      height: 180px;
    }
    .wall_patent {
      display: flex;
      justify-content: space-between;
      margin: 0px;
      padding: 0px;
    }
    .save_patent{
      font-size: 15px;
      margin-left: 60px;
    }
    .save_1_patent{
      font-size: 10px;
      margin-left: 60px;
    }

    .patent_one{
      font-size: 15px;
      
    }
    .patent_two{
      font-size: 10px;
    }
  
  }
@media screen and (min-width: 701px) and (max-width: 1000px){
  
    .mainhead_patent {
      margin-left: auto;
      margin-right: auto;
      font-size:50px;
      text-align: center;
      font-family:fantasy;
      color:rgb(0, 0, 0);
          
      }
    
    .header_patent_2 {
      margin-left: auto;
      margin-right: auto;
      font-size:35px;
      text-align:center;
      font-family:fantasy;
      color:rgb(0, 0, 0);
        
    }
    
    
    .box_patent{
      position:relative;
      left:50px;
      width: 80%;
      height: 100%;   
      padding: 10px;
      border: 5px solid rgb(0, 0, 0);
        
    }
    
    .head_patent{
      font-size: 15px;
      text-align: justify;
    }
    
    .header_patent_1{
      color: rgb(0, 0, 0);
      text-align: justify;
      margin-left: 1%;
      margin-right: 1%;
      font-size:20px;
    }

    .h3_patent{
      color:black;
      font-family:fantasy;
      font-size: 25px;
    }

    .head3_patent{
      color:black;
      font-family:fantasy;
      text-align: center;
      font-size: 25px;
    }
        
      .name_patent{
        color:black;
        text-align: center;
        font-family:fantasy;
      }
    
      .img5_1{   
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 580px;
        width: 550px;
      }
    
      .img5_2{   
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 550px;
        height: 320px;
      }
      
      .img5_3{   
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 520px;
        height: 250px;
      }
      .img5_4{   
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 580px;
        height: 250px;
      }
      .img5_5{   
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 580px;
        height: 180px;
      }
      .wall_patent {
        display: flex;
        justify-content: space-between;
        margin: 0px;
        padding: 0px;
      }
  
  
  }
  
  
  
  @media screen and (min-width: 1001px) and (max-width: 2000px){
  
.mainhead_patent {
  margin-left: auto;
  margin-right: auto;
  font-size:50px;
  text-align: center;
  color:rgb(0, 0, 0);
      
  }

.header_patent_2 {
  margin-left: auto;
  margin-right: auto;
  font-size:30px;
  text-align:center;
  /* font-family:fantasy; */
  color:rgb(0, 0, 0);
    
}


.box_patent{
  position:relative;
  left:150px;
  width: 80%;
  height: 100%;   
  padding: 10px;
  border: 5px solid rgb(0, 0, 0);
    
}

.head_patent{
  font-size: 20px;
  text-align: justify;
}

.header_patent_1{
  color: rgb(0, 0, 0);
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  font-size:20px;
}


  .name_patent{
    color:black;
    text-align: center;
    font-family:fantasy;
  }

  .head3_patent{
    color:black;
    text-align: center;
    font-family:fantasy;
    font-size: 20px;
  }

  .h3_patent{
    color:black;
    font-size: 30px;
    /* text-align: center; */
    /* font-family:fantasy; */
  }

  .img5_1{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 580px;
    width: 700px;
  }

  .img5_2{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 320px;
  }
  
  .img5_3{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 400px;
  }
  .img5_4{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 320px;
    width: 80%;
  }
  .img5_5{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 250px;
    width: 80%;
  }
  .wall_patent {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
  }
  
  }